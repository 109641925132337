import React from 'react'
import PropTypes from 'prop-types'

const Heading = props => {
  return <h1 className="headline tc padding--t">{props.heading}</h1>
}

export default Heading

Heading.propTypes = {
  heading: PropTypes.string.isRequired,
}
